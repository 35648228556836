<template>
    <div>
        <v-container v-if="ready">
            <v-card class="pa-3">

                <v-row no-gutters>
                    <v-col cols="12" sm="8">
                        <h1 class="mb-3" v-if="isEdit">{{ $t('editpost.edit_title') }}</h1>
                        <h1 class="mb-3" v-if="!isEdit">{{ $t('editpost.new_title') }}</h1>
                    </v-col>
                    <v-col cols=12 sm="4">

                        <v-row no-gutters>
                            <v-col cols="12" class="text-right">
                                <v-select hide-details dense
                                color="secondary"
                                    v-model="articolo.stato" outlined :items="postStates"
                                    :label="$t('editpost.post_status')">

                                    <template slot="item" slot-scope="data">
                                        <span class="secondary--text">{{ data.item.text }}</span>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12" class="text-right ">
                                <!-- Data articolo: -->
                                <!-- <v-date-picker v-model="data_articolo" :locale="current_locale"></v-date-picker>
                                <br> -->

                                <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                                    transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense color="secondary" class="mt-6" outlined v-model="data_articolo" :label="$t('editpost.publish_date')"
                                             readonly v-bind="attrs" v-on="on">
                                        </v-text-field>
                                    </template>
                                    <v-date-picker color="primary" v-model="data_articolo" :active-picker.sync="activePicker"
                                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                        min="1950-01-01" @change="save"></v-date-picker>
                                </v-menu>



                                <!-- <input v-model="data_articolo" type="date"> -->
                            </v-col>
                        </v-row>
                        <br>
                    </v-col>
                </v-row>


                <!--  -->
                <v-row>
                    <v-col cols="12" sm="6" v-for="language in languages" :key=language.key>
                        <v-card elevation=12 class="mb-8" :style="mandatoryLanguageboxStyle(language)">
                            <h2 class="pl-4 pt-4">
                                <flag class="mx-1" :iso="languageConverted(language.key)" v-bind:squared="false" />
                                {{ $t('editpost.language_version', { language: language.language }) }}
                            </h2>
                            <v-card-text>
                                <v-text-field :color="style_modalita == ' dark' ? 'secondary' : 'primary'" outlined
                                    :label="$t('editpost.label.title')" v-model.trim="articolo.title[language.key]">
                                </v-text-field>
                                <vue-editor :editorToolbar="customToolbar" class="editor-box"
                                    v-model.trim="articolo.body[language.key]"></vue-editor>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <!-- Date picker -->
                    <v-col cols=12>

                        <!-- <v-menu v-model="datePickerMenu" :close-on-content-click="false" transition="scale-transition"
                            offset-y max-width="290px" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="computedDateFormatted" label="Date (read only text field)"
                                    hint="MM/DD/YYYY format" persistent-hint prepend-icon="mdi-calendar" readonly
                                    v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="date" no-title @input="datePickerMenu = false"></v-date-picker>
                        </v-menu> -->

                    </v-col>

                    <v-col cols=12>
                        <v-card elevation=12 class="mb-8">
                            <h2 class="pl-4 pt-4">
                                {{ $t('editpost.images_list') }}
                            </h2>

                            <v-card-text>

                                <v-row>
                                    <v-col cols="12" sm="4" v-for="(image, index) in articolo.images" :key="index">
                                        <v-card height="230">
                                            <v-img height=200 contain :src="get_image(image)">
                                                <div style="position:relative; top: 10px;left:16px;" class="d-flex ">
                                                    <v-icon v-if="articolo.images[index].default" x-large
                                                        color="yellow">mdi-star</v-icon>
                                                </div>
                                            </v-img>
                                            <!-- </v-card>
                                        <v-card > -->
                                            <div class="d-flex ">
                                                <v-spacer />
                                                <v-btn v-if="!articolo.images[index].default" icon small
                                                    @click="set_cover_image(index)">
                                                    <v-icon :color="blueColor">mdi-star-check</v-icon>
                                                    <!-- {{ $t('editpost.set_cover') }} -->
                                                </v-btn>
                                                <v-btn icon small @click="delete_image(index, image.url)">
                                                    <v-icon color="red">mdi-delete</v-icon>
                                                    <!-- {{ $t('editpost.delete') }} -->
                                                </v-btn>
                                            </div>
                                        </v-card>
                                    </v-col>

                                    <v-col cols="12" sm="4">
                                        <input accept="image/png, image/jpeg" type="file" ref="file"
                                            style="display: none" @change="fileSelect" @click="fileReset">
                                        <v-card @click="$refs.file.click()" min-height="230">
                                            <div class="d-flex flex-column justify-center align-center"
                                                style="height:200px;">
                                                <v-icon x-large>mdi-plus-thick</v-icon>
                                                <h2 class="mt-4">
                                                    {{ $t('editpost.add_image') }}
                                                </h2>
                                            </div>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>


                    </v-col>
                </v-row>


                <v-row>
                    <v-col cols=12 sm=6 class="text-left">
                        <v-btn width=200 :disabled="saving" color="primary" :to="{ name: 'ListPosts' }">
                            <span class="secondary--text">
                                {{ $t('editpost.backToPostsList') }}
                            </span>
                        </v-btn>
                    </v-col>
                    <v-col cols=12 sm=6 class="text-right">
                        <v-btn width=200 :disabled="saving" :loading="saving" :color="blueColor" @click="save_post()">
                            <span class="white--text">
                                {{ $t('editpost.savePost') }}
                            </span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>

            <!-- Dialog salvato con successo -->
            <v-dialog v-model="dialog_save_success" max-width="400" persistent>
                <v-card>
                    <v-card-title>
                        {{ $t('editpost.dialog.savesuccess.title') }}
                    </v-card-title>
                    <v-card-text v-if="isEdit">
                        {{ $t('editpost.dialog.savesuccess.edit.title') }}
                    </v-card-text>
                    <v-card-text v-if="!isEdit">
                        {{ $t('editpost.dialog.savesuccess.new.title') }}
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn outlined :to="{ name: 'ListPosts' }">{{
                                $t('editpost.dialog.savesuccess.backtopostlist')
                        }}</v-btn>

                    </v-card-actions>
                </v-card>

            </v-dialog>

            <!-- Dialog elimina immagine -->
            <v-dialog v-model="delete_image_dialog" max-width="400">
                <v-card>
                    <v-card-title>
                        {{ $t('editpost.dialogdelete_image.title') }}
                    </v-card-title>
                    <v-card-text>
                        {{ $t('editpost.dialogdelete_image.message') }}
                        <v-img contain key="" :src="deleting_url"></v-img>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn :disabled="deleting" outlined @click="delete_image_dialog = false">{{
                                $t('getcontacts.dialogdelete.btn.close')
                        }}</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn :disabled="deleting" outlined color="red" @click="delete_image_confirm()">{{
                                $t('getcontacts.dialogdelete.btn.confirm')
                        }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>


            <!-- Dialog nuova immagine -->
            <v-dialog v-model="dialog_new_image" max-width="400">
                <v-card>
                    <v-card-title>
                        {{ $t('editpost.dialognew_image.title') }}
                    </v-card-title>
                    <v-card-text>
                        {{ $t('editpost.dialognew_image.message') }}
                        <v-img max-width="380" :src="fileURL"></v-img>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn :disabled="uploading" outlined @click="close_dialog_image_upload()">{{
                                $t('getcontacts.dialogdelete.btn.close')
                        }}</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn :disabled="uploading" outlined color="red" @click="upload_image_confirm()">{{
                                $t('getcontacts.dialogdelete.btn.confirm')
                        }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>


        </v-container>
    </div>
</template>

<script>
// import { detectFiles } from "@/plugins/filepicker";

import { VueEditor, Quill } from "vue2-editor";
import {
    firestore,
    storage
} from "@/plugins/firebase"
// import moment from "moment";

import moment from 'moment';

export default {
    props: {},
    components: {
        VueEditor
    },
    data: (vm) => ({
        customToolbar: [
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            ['clean']                                         // remove formatting button

            // ["image", "code-block"]
        ],
        // 
        data_articolo: null,
        activePicker: null,
        menu: false,
        // 
        ready: false,
        idEdit: false,
        articolo: {},
        saving: false,
        dialog_save_success: false,
        // 
        delete_image_dialog: false,
        deleting_index: null,
        deleting_url: null,
        deleting: false,
        // 

        imgFile: null, //
        // 
        // image: null,
        // myFile: null,
        // processing: false,
        fileURL: null,
        dialog_new_image: false,
        uploading: false,
        newFileName: null,
        newFileType: null
    }),
    methods: {
        save(date) {
            this.$refs.menu.save(date)
        },

        //   

        mandatoryLanguageboxStyle(language) {
            // console.log('mandatoryLanguageboxStyle', language);
            if (language.key == 'it') return "border-style: solid; border-color: red;"
        },



        get_image(image) {
            // console.log(image);
            let result
            if (image.url) result = image.url
            if (image.file) result = image.file
            // console.log('result', result);
            return result
        },
        fileReset(file) {
            file.target.value = ''
        },
        fileSelect(file) {
            this.imgFile = file.target.files[0];
            this.fileURL = URL.createObjectURL(this.imgFile);


            if (!this.articolo.images) this.articolo.images = []

            this.articolo.images.push(
                {
                    fileObject: file.target.files[0],
                    file: URL.createObjectURL(this.imgFile),
                    fileName: this.imgFile.name,
                    fileType: this.imgFile.type
                }
            )


            // this.dialog_new_image = true
            // this.newFileName = this.imgFile.name
            // this.newFileType = this.imgFile.type
        },
        upload_image_confirm() {
            // const imgData = new FormData();
            // imgData.append("image", this.myFile);
            this.uploading = true
            const filePath = `images/${Date.now()}-${this.newFileName}`;
            const metadata = { contentType: this.newFileType };
            let storageRef = storage.ref()
                .child(filePath)
                .put(this.imgFile, metadata).then((snapshot) => {

                    snapshot.ref.getDownloadURL().then(url => {
                        let newImagePayload = {}
                        newImagePayload.url = url
                        newImagePayload.default = false
                        if (this.articolo.images) {
                            this.articolo.images.push(newImagePayload)
                        } else {
                            this.articolo.images = []
                            this.articolo.images.push(newImagePayload)
                        }

                        this.articolo = Object.assign({}, this.articolo)


                        const payload = {
                        }
                        payload.id = this.articolo.id
                        payload.data = this.articolo
                        delete payload.data.id

                        // this.saving = true
                        this.$store.dispatch("articoli/act_post_articolo", payload).then(() => {
                            // this.saving = false
                            // this.dialog_save_success = true
                            console.log('images uploaded, post saved');
                            // this.delete_image_dialog = false
                            // this.deleting_index = null
                            this.close_dialog_image_upload()
                        })
                        // this.save_post()
                        // console.log(' * new url', url)
                    })
                    // this.close_dialog_image_upload()
                });

            return storageRef
        },
        // close_dialog_image_upload() {
        //     // this.image = null
        //     // this.myFile = null
        //     this.fileURL = null
        //     // this.processing = false
        //     this.dialog_new_image = false
        //     this.newFileName = null
        //     this.newFileType = null
        //     // 
        //     this.imgFile = null
        //     this.uploading = false
        // },
        delete_image(img_index, url) {
            console.log('delete', url);
            console.log('img_index', img_index);
            this.deleting_url = url
            this.deleting_index = img_index
            this.delete_image_dialog = true
        },
        delete_image_confirm() {
            this.deleting = true
            let payload = {}
            // payload.id = this.deleting_index
            payload.url = this.deleting_url
            try {
                if (payload.url) {
                    this.$store.dispatch("articoli/act_delete_img_articolo", payload).then(() => {
                        // Verifico se impostata come immagine di copertina
                        // ed eventualmente ne imposto una diversa se c'è
                        if (this.articolo.images[this.deleting_index].default) {
                            if (this.articolo.images.length > 1) {
                                this.articolo.images[0].default = true
                            }
                        }
                        // Rimuovo immagine da array immagini articolo
                        this.articolo.images.splice(this.deleting_index, 1)
                        const payload = {
                        }
                        payload.id = this.articolo.id
                        payload.data = this.articolo
                        delete payload.data.id
                        // this.saving = true
                        this.$store.dispatch("articoli/act_post_articolo", payload).then(() => {
                            // this.saving = false
                            // this.dialog_save_success = true
                            console.log('images deleted, post saved');
                            this.delete_image_dialog = false
                            this.deleting_index = null
                            this.deleting_url = null
                            this.deleting = false
                        })
                    })
                }
            } catch {
                // Rimuovo immagine da array immagini articolo
                this.articolo.images.splice(this.deleting_index, 1)
                const payload = {
                }
                payload.id = this.articolo.id
                payload.data = this.articolo
                delete payload.data.id
                // this.saving = true
                this.$store.dispatch("articoli/act_post_articolo", payload).then(() => {
                    // this.saving = false
                    // this.dialog_save_success = true
                    console.log('images deleted, post saved');
                    this.delete_image_dialog = false
                    this.deleting_index = null
                    this.deleting_url = null
                    this.deleting = false
                })
            }

        },

        set_cover_image(index) {
            console.log('set_cover_image', index);
            this.articolo.images.forEach((element, index) => {
                this.articolo.images[index].default = false
            });
            this.articolo.images[index].default = true

            this.articolo.images = [...this.articolo.images]
        },

        languageConverted(language) {
            if (language == 'en') return 'gb'
            return language
        },

        load_articolo(id) {
            this.articolo = {}
            this.$store.dispatch("articoli/act_get_articolo", id).then(articolo => {
                this.articolo = articolo
                this.ready = true
                this.data_articolo = moment(new Date(articolo.creation * 1000)).format("YYYY-MM-DD")
            })

        },
        save_post() {

            console.log('save post');


            // Verifico la presenza di titolo e testo in italiano
            if (this.articolo.title['it'] == "" || this.articolo.body['it'] == "") {
                alert(this.$t('editpost.error.title_body_missing'))
                return
            }


            if (!this.articolo.creation) this.articolo.creation = Math.round(new Date().getTime() / 1000)



            let p = []

            // Se ci sono immagini con proprietà 'file'
            // sono immagini nuove di cui fare upload
            // Nel frattempo verifico se cè impostata l'immagine di copertina

            // if (this.this.articolo.images.length > 0) {

            let cover_image = false
            // console.log(0);
            // p.push(new Promise((resolve, reject) => {
            //     console.log('prom stacking');
            if (this.articolo.images) {
                this.articolo.images.forEach((image, index) => {
                    p.push(new Promise((resolve, reject) => {
                        console.log('prom stacking');
                        if (image.default) cover_image = true
                        if (image.file) {
                            const filePath = `images/${Date.now()}-${image.fileName}`;
                            const metadata = { contentType: image.fileType };
                            storage.ref()
                                .child(filePath)
                                .put(image.fileObject, metadata).then((snapshot) => {
                                    snapshot.ref.getDownloadURL().then(url => {
                                        this.articolo.images[index].url = url
                                        delete this.articolo.images[index].fileObject
                                        delete this.articolo.images[index].file
                                        delete this.articolo.images[index].fileName
                                        delete this.articolo.images[index].fileType
                                        this.articolo = Object.assign({}, this.articolo)
                                        // console.log('url img', this.articolo.images[index].url);
                                        resolve()
                                    })
                                });
                        } else {
                            resolve()
                        }
                    }))
                });
            }
            // }))


            Promise.all(p).then(() => {
                console.log('prom resolved');

                if (!this.articolo.images) this.articolo.images = []

                if (!cover_image && this.articolo.images.length > 0) {
                    this.articolo.images[0].default = true
                }

                const payload = {
                }

                // this.articolo.creation = new Date()
                payload.id = this.articolo.id
                payload.data = Object.assign({}, this.articolo)
                delete payload.data.id
                // console.log(payload.data.images)
                this.saving = true



                console.log('Pre saving');
                console.log(payload.id);
                console.log(payload.data);

                console.log('Launch saving');
                console.log(payload);
                this.$store.dispatch("articoli/act_post_articolo", payload).then(() => {
                    this.saving = false
                    // this.dialog_save_success = true
                    console.log('pre route push');
                    this.$router.push({ name: 'ListPosts' })
                })

            })
            // }
            // console.log(123, this.articolo.images);



            // const payload = {
            // }

            // // this.articolo.creation = new Date()
            // payload.id = this.articolo.id
            // payload.data = Object.assign({}, this.articolo)
            // delete payload.data.id
            // this.saving = true


            // console.log(payload);

            // this.$store.dispatch("articoli/act_post_articolo", payload).then(() => {
            //     // this.saving = false
            //     this.dialog_save_success = true
            // })
        },
        init_new_post() {
            this.articolo.body = {
                en: '',
                it: ''
            }

            this.articolo.title = {
                en: '',
                it: ''
            }
            this.articolo.stato = 0
        }
    },
    mounted() {
        if (!this.$route.params.id_articolo) {
            this.init_new_post()
            this.ready = true
            this.isEdit = false
        } else {
            this.isEdit = true
            this.load_articolo(this.$route.params.id_articolo)
            // this.date = this.articolo.created

        }
    },
    created() { },
    computed: {

        postStates() {
            return [
                {
                    value: 0,
                    text: this.$t('listposts.status.published')
                },
                {
                    value: 1,
                    text: this.$t('listposts.status.draft')
                },
                {
                    value: 2,
                    text: this.$t('listposts.status.archived')
                }
            ]
        },
        languages() {
            return [
                {
                    language: this.$t('editpost.language.it'),
                    key: 'it',
                },
                {
                    language: this.$t('editpost.language.en'),
                    key: 'en',
                },
            ]
        }

    },
    watch: {

        menu(val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'))
        },

        data_articolo(val) {
            this.articolo.creation = Math.round(new Date(val).getTime() / 1000)
        }
    }
}
</script>

<style >
/* .editor-box {
    height:200px;
} */

/* .quillWrapper{
		height: 300px;
		max-height: 300px;
	}*/
.ql-editor {
    max-height: 300px;
    overflow-y: scroll;
}


.v-picker__title.primary {
    color: #1f8ff2
} 

.active.primary--text {
    background: gray;
}

</style>